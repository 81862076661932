.contact-page {
  .gallery {
    height: auto;

    // @include media-breakpoint-down(lg) { 
    //   height: 450px;
    // }
  }
}

.img-logo-2004 {
  width: 234px;
  height: auto;
}

.together {
  font-size: 42px;
  line-height: 1.2;

  span {
    font-size: 48px;
  }

  small {
    font-size: 34px;
  }
}

.hope {
  font-size: 30px;
}

.inquiry {
  font-size: 32px;
}

.address  {
  font-size: 18px;
  font-weight: lighter;
}

