// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$green-1: #98BE51;
$gray-100: #EBEBEC;

// Default variable overrides

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";

// 4. Include any default map overrides here
// Create your own map
$custom-colors: (
  "custom-color": $green-1
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$container-max-widths: (
  xxl: 1140px
);

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/nav";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here