.wedo-page {
  .gallery {
    height: auto;
  }
}

.h-100vh {
  height: 100vh;
}

.h-off-175vh {
  height: calc(100vh - 175px);
}

.h-off-100vh {
  height: calc(100vh - 100px);
}

.web {
  font-size: 44px;
  line-height: 1.2;
}

.bg-gray-2 {
  background-color: #3c4042;
}

.bg-gray-1 {
  background-color: #47494a;
}

.bg-gray {
  background-color: #F1F2F2;
}

.bg-black {
  background-color: #000;
}

.bg-blue-light {
  background-color: #CAEAF4;
  position: relative;

  .bg {
    position: absolute;
    margin-top: -5px;
    z-index: -1;
  }
}

