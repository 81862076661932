// Default variable overrides
$font-family-base: "Hermes";

// Required
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// Required
// @import "node_modules/bootstrap/scss/bootstrap"; 
// Custom Variables for Bootstrap
@import "./abstracts/variables";

// Import all of Bootstrap's CSS
// @import "~bootstrap/scss/bootstrap";

// Custom Theme Pages
header {
  position: relative;
  font-size: 20px;

  .gallery-img {
    width: 100%;
    height: 100%;
  }

  .nav-item {
    min-width: 150px;
  }
}

.img-logo-main {
  width: 250px;
  height: auto;
}

body {
  overflow: hidden;
  overflow-y: auto;
}

@import "./pages/index";
@import "./pages/wedo";
@import "./pages/contact";