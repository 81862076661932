.index-page {
  header {
    // height: calc(100vh - 122px);
    // height: 100vh;

    .gallery-img {
      background-size: contain;
    }

    nav {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
    }

    .bg {
      position: relative;
      top: -6px;
    }
  }
}

.about {
  h6 {
    font-size: 28px;
  }

  h2 {
    font-size: 70px;
  }

  .img-logo-about {
    width: 262px;
    height: auto;
  }
}

.consultant {
  font-size: 26px;

  p {
    font-size: 28px;
  }

  ::marker {
    content: '- ';
  }
}

.present {
  font-size: 30px;

  h3 {
    font-size: 28px;
  }

  ::marker {
    content: '- ';
  }
}

.gallery {
  height: 608px;
  font-size: 28px;
  line-height: 1;

  @include media-breakpoint-down(lg) { 
    height: 300px;
  }

  .gallery-img {
    height: 100%;
    width: 100%;
  }

  .img-logo-gallery {
    width: 262px;
    height: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 30px;
    position: relative;
    height: 100%;
  }
}

.gallery-img {
  background-size: cover;
  background-repeat: no-repeat;
}

footer {
  font-size: 22px;
  line-height: 1;
}